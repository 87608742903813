import React from "react"
import Layout from "../components/layout"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col";
const careerspage = () => (
	<Layout>
		<Row>
			<Col>
				<h1>Careers</h1>
			</Col>

		</Row>
		<Row>
			<Col>
				<h2>What is it like to work at Threesons?</h2>
			</Col>

		</Row>
		<Row>
			<Col>
				<h3>Leadership</h3>
				<p>
					Leaders help themselves and others to do the right things. They set
					direction, build an inspiring vision, and create something new.
					Leadership is about mapping out where you need to go to “win” as a team
					or an organization; and it is dynamic, exciting, and inspiring.
			</p>

				<h3>Integrity</h3>
				<p>
					Integrity is defined as ‘the quality of being honest and having strong
					moral principles’. We are consistent and transparent in our decisions
					and actions. We fulfill our commitments and conduct ourselves in a way
					that is true to our vision. Hence, we also expect the same from our
					employees.
			</p>

				<h3>Motivation</h3>
				<p>
					The sucess or failure of an organization is closely related to the
					effort and motivation of its employees. “Individual commitment to a
					group effort — that is what makes a team work, a company work, a society
					work, a civilization work.” —Vince Lombard
			</p>
			</Col>

		</Row>
		<Row>
			<Col>
				<h3>Apply Now</h3>
			</Col>

		</Row>
		<Row>
			<Col sm={6} xs={12}>
				<Form
					method="POST"
					data-netlify="true"
					name="Careers Form"
					enctype="multipart/form-data">
					<input type="hidden" name="form-name" value="Careers Form" />
					<Form.Group controlId="formBasicName">
						<Form.Label>Name</Form.Label>
						<Form.Control type="text" name="name" />

					</Form.Group>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" name="email" />
						<Form.Text className="text-muted">
							We'll never share your email with anyone else.
					</Form.Text>
					</Form.Group>

					<Form.Group controlId="formBasicPhone">
						<Form.Label>Phone</Form.Label>
						<Form.Control type="number" name="number" />
					</Form.Group>
					<Form.Group controlId="forBasicFile">
						<Form.Control type="file" name="resume" />
					</Form.Group>

					<br />


					<Button className="bootstrapButton" type="submit">
						Submit
				</Button>
				</Form>

			</Col>

		</Row>

	</Layout>
)

export default careerspage
